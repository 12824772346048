import { SectionProps } from "./Section";

export const HEADING_ITEMS: string[] = [
  "Appetizer",
  "Entrée",
  "Dessert",
  "Cocktails",
];

// Fine Dining Menu Items
export const MENU_ITEMS_IN_SECTIONS: SectionProps[] = [
  {
    title: "Appetizers",
    menuItems: [
      {
        title: "Prawn Cocktail",
        description:
          "Jumbo shrimps with a touch of lemon, comes with Marie Rose sauce and salad.",
        price: "FREE",
      },
      {
        title: "Baked Camembert",
        description:
          "A small wheel of Camembert, baked until gooey. Perfect for dipping.",
        price: "FREE",
      },
    ],
  },
  {
    title: "Entrées",
    menuItems: [
      {
        title: "Boneless Ribeye Steak",
        description:
          "Cooked to perfection, served with oven fries and garlic bread.",
        price: "FREE",
      },
      {
        title: "Fresh Maine Lobster",
        description:
          "3lb fresh Maine lobster, lightly seasoned with lemon, garlic, and butter.",
        price: "FREE",
      },
    ],
  },
  {
    title: "Desserts",
    menuItems: [
      {
        title: "Tiramisù",
        description:
          "An Italian coffee-flavored dessert, minus the chocolate topping.",
        price: "FREE",
      },
      {
        title: "Banana Pudding",
        description:
          "The ACTUAL bananna pudding receipe straight from the Magnolia cookbook.",
        price: "FREE",
      },
      {
        title: "Banoffee Pie",
        description:
          "A popular English pie, combining Bananas + Toffee to give you Banoffee over a Graham's cracker crust layered with whipped cream.",
        price: "FREE",
      },
    ],
  },
  {
    title: "Cocktails",
    menuItems: [
      {
        title: "Raspberry Mojito",
        description: "The classic Cuban cocktail, with a raspberry twist.",
        price: "FREE",
      },
      {
        title: "Piña Colada",
        description:
          "This blend of rum, pineapple, and coconut milk will have you wishing you were in The Bahamas.",
        price: "FREE",
      },
      {
        title: "Moscow Mule",
        description: "Lime, ginger, and ton of Russian WÓDKA.",
        price: "FREE",
      },
      {
        title: "Martini",
        description:
          "An Italian cocktail made with vodka, vermouth, and olive oil. Shaken, not stirred.",
        price: "FREE",
      },
    ],
  },
];
