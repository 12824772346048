import React from "react";

import "./Footer.css";

const Footer = () => (
  <div className="Footer">
    <img
      className="Footer__signature"
      src="/signature.png"
      alt="Chef Signature"
    />
    <p className="Footer__chefName">– Chef Mellòr</p>
  </div>
);

export default Footer;
