import React from "react";

import "./Section.css";

export type MenuItem = {
  title: string;
  description: string;
  price: string;
};

export type SectionProps = {
  title: string;
  menuItems: MenuItem[];
};

const Section = ({ title, menuItems }: SectionProps) => (
  <div className="Section">
    <h2 className="Section__header">{title}</h2>
    <ul>
      {menuItems.map(({ title, price, description }) => (
        <li>
          <div className="Section__item__titleAndPrice">
            <h3>{title}</h3>
            <span className="Section__item__titleAndPrice__line" />
            <span className="Section__item__titleAndPrice__price">{price}</span>
          </div>
          <p className="Section__item__description">{description}</p>
        </li>
      ))}
    </ul>
  </div>
);

export default Section;
