import React, { useEffect } from "react";

import Section from "./Section";
import { MENU_ITEMS_IN_SECTIONS } from "./constants";
import Footer from "./Footer";

import "./App.css";

const App = () => {
  if (window.innerWidth > 500) {
    return (
      <div className="App">
        <header className="App-header">
          <h1>
            <span className="Logo-text">TO</span>
            <span className="Logo-dot">•</span>
            <span className="Logo-text">VA</span>
          </h1>
          <h2>View on iPhone to Continue</h2>
        </header>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>
          <span className="Logo-text">TO</span>
          <span className="Logo-dot">•</span>
          <span className="Logo-text">VA</span>
        </h1>
        <h2>Luxury Fine Dining</h2>
      </header>
      <div>
        <img
          src="/floorplan.png"
          alt="floorplan"
          style={{
            width: "100%",
            marginTop: -65,
            marginLeft: 48,
            marginRight: -48,
            marginBottom: -38,
          }}
        />
      </div>
      <div
        style={{
          backgroundImage: "url(/background_2.png)",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          margin: -32,
          padding: 32,
        }}
      >
        <Section {...MENU_ITEMS_IN_SECTIONS[0]} />
        <Section {...MENU_ITEMS_IN_SECTIONS[1]} />
      </div>
      <div style={{ position: "relative" }}>
        <img
          src="/hearts.png"
          alt="floorplan"
          style={{
            width: "50%",
            marginTop: -63,
            marginBottom: -32,
            opacity: 1,
            right: -53,
            position: "absolute",
          }}
        />
      </div>
      <div
        style={{
          backgroundImage: "url(/background_3.png)",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          margin: -32,
          padding: 32,
        }}
      >
        <Section {...MENU_ITEMS_IN_SECTIONS[2]} />
        <Section {...MENU_ITEMS_IN_SECTIONS[3]} />
      </div>
      <Footer />
      <div>
        <img
          src="/ipo.png"
          alt="floorplan"
          style={{
            width: "100%",
            margin: "-106px -55px -35px -30px",
          }}
        />
      </div>
    </div>
  );
};

export default App;
